import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom"; // , Switch, Link
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Ontwerpen from "./pages/Ontwerpen";
import Werkwijze from "./pages/Werkwijze";
import Tarieven from "./pages/Tarieven";
import Contact from "./pages/Contact";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} exact></Route>
          <Route path="/ontwerpen" element={<Ontwerpen />}></Route>
          <Route path="/werkwijze" element={<Werkwijze />}></Route>
          <Route path="/tarieven" element={<Tarieven />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
