import "./Contact.css";
import Rozen2 from "../assets/images/examples/rozen-2.jpeg";
import iconAdress from '../assets/images/icons/adress.png'
import iconEmail from '../assets/images/icons/email-2-2.png'
import iconPhone from '../assets/images/icons/phone.png'



const Contact = () => {
  return (
    <div>
      <img className="background-index" src={Rozen2} alt="fireSpot"/>

      <section className="contact">
        <div className="contact-title">
          <p>Contact</p>
          <div className="contact-information-line"></div>
        </div>

        <section className="contact-text-data">
          <div className="contact-text">
            <p>
              Mocht je graag een afpsraak willen maken voor een raam, dan ben ik
              bereikbaar via email en telefoon. Voor andere vragen over
              bijvoorbeeld het ontwerpen of restaureren van ramen kan je ook
              gewoon bij mij terecht. Hopelijk tot snel!
            </p>
          </div>

          <div className="contact-data">
            <div className="footer-information">
              <div className="footer-information-section">
                <div className="icon-and-text">
                  <img className="icon-adress" src={iconAdress} alt="fireSpot"/>
                  <p>Adress</p>
                </div>

                <p>blablabla</p>
              </div>

              <div className="footer-information-section">
                <div className="icon-and-text">
                  <img
                    className="icon-email"
                    src={iconEmail}
                    alt="fireSpot"
                  />
                  <p>Email</p>
                </div>

                <p>bladiebla@mail.com</p>
              </div>

              <div className="footer-information-section">
                <div className="icon-and-text">
                  <img className="icon-phone" src={iconPhone} alt="fireSpot"/>
                  <p>Phone</p>
                </div>

                <p>123456789</p>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default Contact;
