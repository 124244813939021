import "./Tarieven.css";
import Rozen2 from "../assets/images/examples/rozen-2.jpeg";
import halfRaam2 from "../assets/images/examples/half-raam-2.jpeg";



const Tarieven = () => {
  return (
    <div>
      <img className="background-index" src={Rozen2} alt="fireSpot"/>

      <section className="tarieven">
        <div className="tarieven-title">
          <p>Tarieven</p>
          <div className="tarieven-title-line"></div>
        </div>

        <div className="sidebar-left"></div>
        <div className="bar-bottom"></div>

        <div className="tarieven-section">
          <div className="tarieven-text">
            <p>
              Bij het maken van glas in lood zijn er een hoop factoren die de
              prijs beinvloeden. Denk hierbij aan het soort glas, de dikte van
              het lood maar vooral ook het ontwerp. Simpele ontwerpen kosten
              over het algemeen minder manuren in vergelijken tot complexere
              ontwerpen. Het is daarom erg lastig om een prijsindicatie te geven
              en dat is ook waarom de meeste glas-in-lood bedrijven dit niet
              doen of simpelweg een grove schatting geven van de minimumkosten.
              bij Glasatelier Azalea doen wij dus ook dat laatste en geven wij
              een minimumprijs per vierkante meter als indicatie. Voor een
              betere inschatting kunt u beter contact opnemen met de glazenier
              waarvan u de contactgegevens onder aan deze pagina kunt vinden of
              op de
              <span
                className="contact-link"
              >
                contact pagina
              </span>
              .
            </p>
          </div>

          <div className="tarieven-text-2">Minimumprijs per m&#178; 500&#8364;</div>

          <div className="tarieven-text">
            Voor meer informatie over wat de prijs nog meer beïnvloed is
            hieronder een overzicht te vinden met ontwerpkeuzes die de
            uiteindelijke prijs van een raam bepalen. Mocht u het hier liever
            over hebben met de glazenier dan kunt u zoals eerder gezegd ook
            gewoon contact opnemen!
          </div>
        </div>
      </section>

      <section className="enumeration">
        <div className="sidebar-left"></div>
        <div className="bar-bottom"></div>

        <p className="enumeration-title">Wat beinvloed de prijzen?</p>

        <div className="enumeration-section">
          <div className="enumeration-section-text">
            <p className="enumeration-section-title">Het soort lood</p>

            <p>
              Het glas is dit en dat blablabla want we hebben zus en zo en ik
              heb gewoon wat text nodig om te zien hoe het eruit gaat zien dus
              ik schrijf maar effe wat hier en dit kan mooi later worden
              aangepast
            </p>
          </div>

          <div className="enumeration-section-image">
            <img
              className="enumeration-section-image-image"
              src={halfRaam2}
              alt="fireSpot"
            />
          </div>

          <div className="enumeration-section-image">
            <img
              className="enumeration-section-image-image"
              src={halfRaam2}
              alt="fireSpot"
            />
          </div>
        </div>

        <div className="enumeration-section">
          <div className="enumeration-section-text">
            <p className="enumeration-section-title">Het soort lood</p>

            <p>
              Het glas is dit en dat blablabla want we hebben zus en zo en ik
              heb gewoon wat text nodig om te zien hoe het eruit gaat zien dus
              ik schrijf maar effe wat hier en dit kan mooi later worden
              aangepast
            </p>
          </div>

          <div className="enumeration-section-image">
            <img
              className="enumeration-section-image-image"
              src={halfRaam2}
              alt="fireSpot"
            />
          </div>

          <div className="enumeration-section-image">
            <img
              className="enumeration-section-image-image"
              src={halfRaam2}
              alt="fireSpot"
            />
          </div>
        </div>

        <div className="enumeration-section">
          <div className="enumeration-section-text">
            <p className="enumeration-section-title">Het soort lood</p>

            <p>
              Het glas is dit en dat blablabla want we hebben zus en zo en ik
              heb gewoon wat text nodig om te zien hoe het eruit gaat zien dus
              ik schrijf maar effe wat hier en dit kan mooi later worden
              aangepast
            </p>
          </div>

          <div className="enumeration-section-image">
            <img
              className="enumeration-section-image-image"
              src={halfRaam2}
              alt="fireSpot"
            />
          </div>

          <div className="enumeration-section-image">
            <img
              className="enumeration-section-image-image"
              src={halfRaam2}
              alt="fireSpot"
            />
          </div>
        </div>

        <div className="enumeration-section">
          <div className="enumeration-section-text">
            <p className="enumeration-section-title">Het soort lood</p>

            <p>
              Het glas is dit en dat blablabla want we hebben zus en zo en ik
              heb gewoon wat text nodig om te zien hoe het eruit gaat zien dus
              ik schrijf maar effe wat hier en dit kan mooi later worden
              aangepast
            </p>
          </div>

          <div className="enumeration-section-image">
            <img
              className="enumeration-section-image-image"
              src={halfRaam2}
              alt="fireSpot"
            />
          </div>

          <div className="enumeration-section-image">
            <img
              className="enumeration-section-image-image"
              src={halfRaam2}
              alt="fireSpot"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Tarieven;
