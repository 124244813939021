import "./Header.css";
import Logo from "../assets/images/general/logo-resized.svg";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  useEffect(() => {
    // Makes the header disappear when you scroll down and reappear when you scroll up
    let scroll = window.pageYOffset;
    const header = document.getElementById("header");
    const headerStyle = window.getComputedStyle(header);
    const headerHeight = parseFloat(headerStyle.height);

    const handleScroll = () => {
      const scrolled = window.pageYOffset;

      if (scrolled > headerHeight - 1) {
        if (scrolled > scroll + 4) {
          header.classList.add("animate-header");
        } else if (scrolled < scroll - 4) {
          header.classList.remove("animate-header");
        }
      }
      scroll = window.pageYOffset;
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Run this effect only once after initial render

  useEffect(() => {
    window.navigation.addEventListener("navigate", (event) => {
      let oldLocation = window.location.href.split("/");
      oldLocation = oldLocation[oldLocation.length - 1];

      let oldButton;
      if (oldLocation === "") {
        oldButton = document.getElementById("logo-button");
      } else {
        oldButton = document.getElementById(`${oldLocation}-button`);
      }

      oldButton.classList.remove("active-button");
      const lineOld = oldButton.getElementsByClassName("header-line")[0];
      lineOld.classList.remove("active-line");

      let currentLocation = event.destination.url.split("/");
      currentLocation = currentLocation[currentLocation.length - 1];

      let currentButton;
      if (currentLocation === "") {
        currentButton = document.getElementById("logo-button");
      } else {
        currentButton = document.getElementById(`${currentLocation}-button`);
      }

      currentButton.classList.add("active-button");
      const line = currentButton.getElementsByClassName("header-line")[0];
      line.classList.add("active-line");
    });

    window.addEventListener("locationchange", function () {
      console.log("location changed 2!");
    });

    // const activeHeaderButton = document.getElementById('logo-button');
    // console.log(activeHeaderButton);
    // const line = activeHeaderButton.getElementsByClassName("header-line")[0];
    // line.classList.add("active-line");
    // activeHeaderButton.classList.add("active-button");

    const introList = document.getElementsByClassName("introduction");

    if (introList.length > 0) {
      const intro = introList[0];
      const style = window.getComputedStyle(intro);
      const originalMarginTop = parseFloat(style.marginTop);
      const originalMarginBottom = parseFloat(style.marginBottom);

      const handleScroll = () => {
        const scrolled = window.pageYOffset;

        if (scrolled > 0 && scrolled < originalMarginBottom) {
          const newMarginTop = originalMarginTop + scrolled;
          const newMarginBottom = originalMarginBottom - scrolled;

          intro.style.marginBottom = newMarginBottom + "px";
          intro.style.marginTop = newMarginTop + "px";
        }
      };

      window.addEventListener("scroll", handleScroll);

      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []); // Run this effect only once after initial render

  console.log("here");

  return (
    <header className="header" id="header">
      <div className="tabs">
        <Link to="/" id="logo-button" className="logo-button">
          <img className="logo-image" src={Logo} alt="fireSpot" />
          <p>Glasatelier Azalea</p>
          <div className="header-line"></div>
        </Link>

        <Link
          to="/ontwerpen"
          id="ontwerpen-button"
          className="ontwerpen-button"
        >
          <p>Ontwerpen</p>
          <div className="header-line"></div>
        </Link>

        <Link
          to="/werkwijze"
          id="werkwijze-button"
          className="werkwijze-button"
        >
          <p>Werkwijze</p>
          <div className="header-line"></div>
        </Link>

        <Link to="/tarieven" id="tarieven-button" className="tarieven-button">
          <p>Tarieven</p>
          <div className="header-line"></div>
        </Link>

        <Link to="/contact" id="contact-button" className="contact-button">
          <p>Contact</p>
          <div className="header-line"></div>
        </Link>
      </div>
    </header>
  );
};

export default Header;
