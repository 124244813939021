import "./Ontwerpen.css";
import Rozen2 from "../assets/images/examples/rozen-2.jpeg";
import schuifDeur1 from "../assets/images/examples/schuifdeur-1.jpg";
import schuifDeur2 from "../assets/images/examples/schuifdeur 2.jpg";
import voorraam from "../assets/images/examples/voorraam.jpg";
import installatie2 from "../assets/images/examples/installatie-2.jpeg";
import halfRaam1 from "../assets/images/examples/half-raam-1.jpeg";
import React, { useEffect } from "react";



const Ontwerpen = () => {
  useEffect(() => {
    const handleArrowClick = (direction) => {
      const box = document.querySelector(".examples-menu");
      const scrollAmount = box.offsetWidth / 1;

      box.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    };

    const arrowLeft = document.querySelector(".arrow-left");
    const arrowRight = document.querySelector(".arrow-right");

    if (arrowLeft && arrowRight) {
      arrowLeft.addEventListener("click", () => handleArrowClick("left"));
      arrowRight.addEventListener("click", () => handleArrowClick("right"));
    }

    // Clean up event listeners when the component unmounts
    return () => {
      if (arrowLeft && arrowRight) {
        arrowLeft.removeEventListener("click", () => handleArrowClick("left"));
        arrowRight.removeEventListener("click", () => handleArrowClick("right"));
      }
    };
  }, []); // Run this effect only once after initial render

  return (
    <div>
      <img className="background-index" src={Rozen2} alt="fireSpot"/>
      <section className="introduction">
        <p className="introduction-title">Ontwerpen</p>

        <div className="introduction-line"></div>

        <p className="introduction-subtitle">
          Specialist in glas-in-lood &#124; Design op maat
        </p>

        <p className="introduction-body">
          Ik ben Monique Beelen en ik maak glas in lood Ik ben Monique Beelen en
          ik maak glas in lood Ik ben Monique Beelen en ik maak glas in lood Ik
          ben Monique Beelen en ik maak glas in lood
        </p>
      </section>

      <section className="examples">

      <div className="center-left"><div className="arrow-left"></div></div>
      <div className="center-right"><div className="arrow-right"></div></div>

        <div className="examples-menu">
          <div className="design-example" id="design-example-1">
            <div className="design-example-text">
              <p className="design-title">Example-1</p>
              <p className="design-text">
                Wat text bij het voorbeeld Wat text bij het voorbeeld Wat text
                bij het voorbeeld Wat text bij het voorbeeld
              </p>
            </div>
            <img className="design-example-image" src={schuifDeur1} alt="fireSpot"/>
          </div>

          <div className="design-example" id="design-example-2">
            <div className="design-example-text">
              <p className="design-title">Example-2</p>
              <p className="design-text">
                Wat text bij het voorbeeld Wat text bij het voorbeeld Wat text
                bij het voorbeeld Wat text bij het voorbeeld
              </p>
            </div>
            <img className="design-example-image" src={voorraam} alt="fireSpot"/>
          </div>

          <div className="design-example">
            <div className="design-example-text">
              <p className="design-title">Title</p>
              <p className="design-text">
                Wat text bij het voorbeeld Wat text bij het voorbeeld Wat text
                bij het voorbeeld Wat text bij het voorbeeld
              </p>
            </div>
            <img className="design-example-image" src={schuifDeur2} alt="fireSpot" />
          </div>

          <div className="design-example">
            <div className="design-example-text">
              <p className="design-title">Test</p>
              <p className="design-text">
                Wat text bij het voorbeeld Wat text bij het voorbeeld Wat text
                bij het voorbeeld Wat text bij het voorbeeld
              </p>
            </div>
            <img className="design-example-image" src={schuifDeur2} alt="fireSpot" />
          </div>

          <div className="design-example">
            <div className="design-example-text">
              <p className="design-title">Test2</p>
              <p className="design-text">
                Wat text bij het voorbeeld Wat text bij het voorbeeld Wat text
                bij het voorbeeld Wat text bij het voorbeeld
              </p>
            </div>
            <img className="design-example-image" src={schuifDeur2} alt="fireSpot"/>
          </div>

          <div className="design-example">
            <div className="design-example-text">
              <p className="design-title">Title</p>
              <p className="design-text">
                Wat text bij het voorbeeld Wat text bij het voorbeeld Wat text
                bij het voorbeeld Wat text bij het voorbeeld
              </p>
            </div>
            <img className="design-example-image" src={schuifDeur2} alt="fireSpot"/>
          </div>

          <div className="design-example">
            <div className="design-example-text">
              <p className="design-title">Title</p>
              <p className="design-text">
                Wat text bij het voorbeeld Wat text bij het voorbeeld Wat text
                bij het voorbeeld Wat text bij het voorbeeld
              </p>
            </div>
            <img className="design-example-image" src={schuifDeur2} alt="fireSpot"/>
          </div>

          <div className="design-example">
            <div className="design-example-text">
              <p className="design-title">Title</p>
              <p className="design-text">
                Wat text bij het voorbeeld Wat text bij het voorbeeld Wat text
                bij het voorbeeld Wat text bij het voorbeeld
              </p>
            </div>
            <img className="design-example-image" src={voorraam} alt="fireSpot"/>
          </div>

          <div className="design-example">
            <div className="design-example-text">
              <p className="design-title">Title</p>
              <p className="design-text">
                Wat text bij het voorbeeld Wat text bij het voorbeeld Wat text
                bij het voorbeeld Wat text bij het voorbeeld
              </p>
            </div>
            <img className="design-example-image" src={schuifDeur2} alt="fireSpot" />
          </div>

          <div className="design-example">
            <div className="design-example-text">
              <p className="design-title">Title</p>
              <p className="design-text">
                Wat text bij het voorbeeld Wat text bij het voorbeeld Wat text
                bij het voorbeeld Wat text bij het voorbeeld
              </p>
            </div>
            <img className="design-example-image" src={schuifDeur2} alt="fireSpot"/>
          </div>

          <div className="design-example">
            <div className="design-example-text">
              <p className="design-title">Title</p>
              <p className="design-text">
                Wat text bij het voorbeeld Wat text bij het voorbeeld Wat text
                bij het voorbeeld Wat text bij het voorbeeld
              </p>
            </div>
            <img className="design-example-image" src={schuifDeur2} alt="fireSpot" />
          </div>

          <div className="design-example">
            <div className="design-example-text">
              <p className="design-title">Title</p>
              <p className="design-text">
                Wat text bij het voorbeeld Wat text bij het voorbeeld Wat text
                bij het voorbeeld Wat text bij het voorbeeld
              </p>
            </div>
            <img className="design-example-image" src={schuifDeur2} alt="fireSpot"/>
          </div>
        </div>
      </section>

      <section className="grid-text-image">
        <div className="grid-text-pink">
          <p className="grid-text-title">Eigen Ontwerp?</p>

          <p className="grid-text-text">
            Ik ben Monique Beelen en ik maak glas in lood Ik ben Monique Beelen
            en ik maak glas in lood Ik ben Monique Beelen en ik maak glas in
            lood Ik ben Monique Beelen en ik maak glas in lood
          </p>
        </div>

        <div className="grid-image">
          <img className="grid-image-image" src={halfRaam1} alt="fireSpot"/>
        </div>

        <div className="grid-image">
          <img className="grid-image-image" src={installatie2} alt="fireSpot"/>
        </div>

        <div className="grid-text-yellow">
          <p className="grid-text-title">Geen eigen ontwerp?</p>

          <p className="grid-text-text">
            Ik ben Monique Beelen en ik maak glas in lood Ik ben Monique Beelen
            en ik maak glas in lood Ik ben Monique Beelen en ik maak glas in
            lood Ik ben Monique Beelen en ik maak glas in lood
          </p>
        </div>
      </section>
    </div>
  );
};

export default Ontwerpen;
