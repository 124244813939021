import "./Werkwijze.css";
import halfRaam3 from "../assets/images/examples/half-raaam-3.jpeg";
import moniqueMask from "../assets/images/monique/monique-mask.jpeg";
import halfRaam1 from "../assets/images/examples/half-raam-1.jpeg";
import installatie2 from "../assets/images/examples/installatie-2.jpeg";



const Werkwijze = () => {
  return (
    <div>
      <img className="background-index" src={halfRaam3} alt="fireSpot" />

      <section className="introduction">
        <p className="introduction-title">Werkwijze</p>

        <div className="introduction-line"></div>

        <p className="introduction-subtitle">
          Specialist in glas-in-lood &#124; Design op maat
        </p>

        <p className="introduction-body">
          Ik ben Monique Beelen en ik maak glas in lood Ik ben Monique Beelen en
          ik maak glas in lood Ik ben Monique Beelen en ik maak glas in lood Ik
          ben Monique Beelen en ik maak glas in lood
        </p>
      </section>

      <section className="flex-text-image">
        <div className="flex-image">
          <img
            className="flex-image-image"
            src={moniqueMask}
            alt="fireSpot"
          />
        </div>

        <div className="flex-text">
          <div className="flex-text-top">
            <p className="flex-text-title">Mijn Deel</p>

            <p className="flex-text-text">
              Ik ben Monique Beelen en ik maak glas in lood Ik ben Monique
              Beelen en ik maak glas in lood Ik ben Monique Beelen en ik maak
              glas in lood Ik ben Monique Beelen en ik maak glas in lood Ik ben
              Monique Beelen en ik maak glas in lood Ik ben Monique Beelen en ik
              maak glas in lood
            </p>
          </div>

          <div className="flex-text-bottom">
            <p className="flex-text-title">Jouw Deel</p>

            <p className="flex-text-text">
              Ik ben Monique Beelen en ik maak glas in lood Ik ben Monique
              Beelen en ik maak glas in lood Ik ben Monique Beelen en ik maak
              glas in lood Ik ben Monique Beelen en ik maak glas in lood Ik ben
              Monique Beelen en ik maak glas in lood Ik ben Monique Beelen en ik
              maak glas in lood
            </p>
          </div>
        </div>
      </section>

      <section className="flex-text-image">
        <div className="flex-text">
          <div className="flex-text-top">
            <p className="flex-text-title">Het Ontwerpen</p>

            <p className="flex-text-text">
              Ik ben Monique Beelen en ik maak glas in lood Ik ben Monique
              Beelen en ik maak glas in lood Ik ben Monique Beelen en ik maak
              glas in lood Ik ben Monique Beelen en ik maak glas in lood Ik ben
              Monique Beelen en ik maak glas in lood Ik ben Monique Beelen en ik
              maak glas in lood
            </p>
          </div>

          <div className="flex-text-bottom">
            <p className="flex-text-title">Inspiratie</p>

            <p className="flex-text-text">
              Ik ben Monique Beelen en ik maak glas in lood Ik ben Monique
              Beelen en ik maak glas in lood Ik ben Monique Beelen en ik maak
              glas in lood Ik ben Monique Beelen en ik maak glas in lood Ik ben
              Monique Beelen en ik maak glas in lood Ik ben Monique Beelen en ik
              maak glas in lood Ik ben Monique Beelen en ik maak glas in lood Ik
              ben Monique Beelen en ik maak glas in lood Ik ben Monique Beelen
              en ik maak glas in lood
            </p>
          </div>
        </div>

        <div className="flex-image">
          <img
            className="flex-image-image"
            src={halfRaam1}
            alt="fireSpot"
          />
        </div>
      </section>

      <section className="grid-text-image">
        <div className="grid-text-pink">
          <p className="grid-text-title">Uitwerken</p>

          <p className="grid-text-text">
            Ik ben Monique Beelen en ik maak glas in lood Ik ben Monique Beelen
            en ik maak glas in lood Ik ben Monique Beelen en ik maak glas in
            lood Ik ben Monique Beelen en ik maak glas in lood
          </p>
        </div>

        <div className="grid-image">
          <img
            className="grid-image-image"
            src={halfRaam1}
            alt="fireSpot"
          />
        </div>

        <div className="grid-image">
          <img
            className="grid-image-image"
            src={installatie2}
            alt="fireSpot"
          />
        </div>

        <div className="grid-text-yellow">
          <p className="grid-text-title">Installeren</p>

          <p className="grid-text-text">
            Ik ben Monique Beelen en ik maak glas in lood Ik ben Monique Beelen
            en ik maak glas in lood Ik ben Monique Beelen en ik maak glas in
            lood Ik ben Monique Beelen en ik maak glas in lood
          </p>
        </div>
      </section>
    </div>
  );
};

export default Werkwijze;
