import "./Home.css";
import Rozen2 from "../assets/images/examples/rozen-2.jpeg";
import MoniqueMask from "../assets/images/monique/monique-mask.jpeg";
import HalfRaam1 from "../assets/images/examples/half-raam-1.jpeg";
import Installatie2 from "../assets/images/examples/installatie-2.jpeg";


const Home = () => {
  return (
    
    <div className="home">

      <img className="background-index" src={Rozen2} alt="fireSpot"/>

      <section className="introduction">
        <p className="introduction-title">Glasatelier Azalea</p>

        <div className="introduction-line"></div>

        <p className="introduction-subtitle">
          Specialist in glas-in-lood &#124; Design op maat
        </p>

        <p className="introduction-body">
          Ik ben Monique Beelen en ik maak glas in lood Ik ben Monique Beelen en
          ik maak glas in lood Ik ben Monique Beelen en ik maak glas in lood Ik
          ben Monique Beelen en ik maak glas in lood
        </p>
      </section>

      <section className="flex-text-image">
        <div className="flex-text">
          <div className="flex-text-top">
            <p className="flex-text-title">Wie ben ik?</p>

            <p className="flex-text-text">
              Ik ben Monique Beelen en ik maak glas in lood Ik ben Monique
              Beelen en ik maak glas in lood Ik ben Monique Beelen en ik maak
              glas in lood Ik ben Monique Beelen en ik maak glas in lood Ik ben
              Monique Beelen en ik maak glas in lood Ik ben Monique Beelen en ik
              maak glas in lood
            </p>
          </div>

          <div className="flex-text-bottom">
            <p className="flex-text-title">Wat doe ik?</p>

            <p className="flex-text-text">
              Ik ben Monique Beelen en ik maak glas in lood Ik ben Monique
              Beelen en ik maak glas in lood Ik ben Monique Beelen en ik maak
              glas in lood Ik ben Monique Beelen en ik maak glas in lood Ik ben
              Monique Beelen en ik maak glas in lood Ik ben Monique Beelen en ik
              maak glas in lood
            </p>
          </div>
        </div>

        <div className="flex-image">
          <img
            className="flex-image-image"
            src={MoniqueMask}
            alt="fireSpot"
          />
        </div>
      </section>

      <section className="grid-text-image">
        <div className="grid-text-pink">
          <p className="grid-text-title">Mijn Atelier</p>

          <p className="grid-text-text">
            Ik ben Monique Beelen en ik maak glas in lood Ik ben Monique Beelen
            en ik maak glas in lood Ik ben Monique Beelen en ik maak glas in
            lood Ik ben Monique Beelen en ik maak glas in lood
          </p>
        </div>

        <div className="grid-image">
          <img
            className="grid-image-image"
            src={HalfRaam1}
            alt="fireSpot"
          />
        </div>

        <div className="grid-image">
          <img
            className="grid-image-image"
            src={Installatie2}
            alt="fireSpot"
          />
        </div>

        <div className="grid-text-yellow">
          <p className="grid-text-title">Mijn ontwerpen</p>

          <p className="grid-text-text">
            Ik ben Monique Beelen en ik maak glas in lood Ik ben Monique Beelen
            en ik maak glas in lood Ik ben Monique Beelen en ik maak glas in
            lood Ik ben Monique Beelen en ik maak glas in lood
          </p>
        </div>
      </section>
    </div>
  );
};

export default Home;
