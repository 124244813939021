import "./Footer.css";
import iconAdress from '../assets/images/icons/adress.png'
import iconEmail from '../assets/images/icons/email-2-2.png'
import iconPhone from '../assets/images/icons/phone.png'

// import iconEmail from '../assets/images/icons/email-2-2.png'
// import iconEmail from '../assets/images/icons/email-2-2.png'


const Footer = () => {
  return (
    <div>
      <section className="footer">
        <p className="footer-title">Contact</p>

        <div className="footer-information">
          <div className="footer-information-section">
            <div className="icon-and-text">
              <img
                className="icon-adress"
                src={iconAdress}
                alt="fireSpot"
              />
              <p>Adress</p>
            </div>

            <p>blablabla</p>
          </div>

          <div className="footer-information-section">
            <div className="icon-and-text">
              <img
                className="icon-email"
                src={iconEmail}
                alt="fireSpot"
              />
              <p>Email</p>
            </div>

            <p>bladiebla@mail.com</p>
          </div>

          <div className="footer-information-section">
            <div className="icon-and-text">
              <img
                className="icon-phone"
                src={iconPhone}
                alt="fireSpot"
              />
              <p>Phone</p>
            </div>

            <p>123456789</p>
          </div>
        </div>

        <div className="footer-information-line"></div>
      </section>

      <section className="credit">
        <p>Web Design by Simon and Sanne Mariani</p>
      </section>
    </div>
  );
};

export default Footer;
